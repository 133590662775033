@font-face {
    font-family: 'Myriad Pro';
    src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
  }
  

.label-style-create-franchisee,
.input-field-dropdown-styling
{
  letter-spacing: -0.05em !important;
  top: 8px !important;
}

.label-style-create-franchisee.Mui-focused {
  top: 0px !important;
}

.label-style-create-franchisee.MuiInputLabel-shrink {
  top: 0px !important;
}

.select-drop-down-label-styling>[class *="Mui-focused"] {
  top: 0px !important;
}

.MuiSvgIcon-root-MuiSelect-icon {
  color: #003087 !important;

}

.radio-button-style [class *="MuiSvgIcon-root"] {
  color: #003087 !important;

}


/* Css class for step bar text styling when on active page*/
.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  color: #003087 !important;
}

/* Css class for step bar text styling when on remaining page*/

.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label,
.step-labeling span.Mui-disabled {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000000 !important;
}

/* Css class for step bar text styling when page is completed*/

.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-completed,
.step-labeling,
.step-labeling span.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  letter-spacing: -0.022em;
  color: #003087 !important;
}

/* Css class for step bar when it is not active */
.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root {
  color: transparent !important
}

.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root.Mui-active {
  color: #003087 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 0.8rem !important;
  width: 0.8rem !important;

}

/* Css class for display text of step no as none*/

.MuiStepIcon-text {
  display: none !important;
}

/* Css for step bar when step is completed*/

.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.Mui-completed {


  color: #003087 !important;
  /* margin: auto !important; */
  margin: -3px !important;
  vertical-align: middle !important;
  height: auto !important;
  border-radius: 100% !important;
  width: 1.5em !important;
  /*border: solid #003087 !important ;
    border-spacing:0px !important;*/

}

.step-labeling>.MuiStepLabel-iconContainer {

  border-radius: 50% !important;
  border: 2px solid #003087 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;


}

/* Css styling for horizontal line */

/* .MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #003087 !important;
} */

.Profile-parent
{
  border: 2px dashed   rgba(0, 48, 135, 0.5) !important;
  height: 70px !important;
  border-radius: 10px !important;
}

.clear-button {
  border: 2px solid #003087 !important;
  border-radius: 10px !important;
  width: 146px !important;
  margin-left: 30px !important;
  margin-top: 55px !important;
  background: #ffffff !important;
}

.clear-button-text {
  text-align: center !important;
}

.text-input [class*="MuiInputBase-root-MuiOutlinedInput-root"] {
  box-sizing: border-box !important;
  min-width: 100%;
  height: 70px !important;
  background: #ffffff !important;
  /* border: 2px solid rgba(0, 48, 135, 0.5) !important; */
  border-radius: 12px !important;
}

/* Css class to remove confirm dialog box container from DOM */
.closeEditClient
{
  display: none !important;
}
