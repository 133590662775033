@font-face {
    font-family: 'Myriad Pro';
    src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
  }
  
  @font-face {
    font-family: 'Myriad Pro Bold';
    src: url('/public/MyriadPro-Bold.otf') format('truetype');
  }
  
  @font-face {
    font-family: 'Bebas Neue';
    src: url('/public/BebasNeue.ttf') format('truetype');
  }
  
  
  @font-face {
    font-family: 'Myriad Pro';
    src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
  }
  
  .input-field-styling [class *="MuiInputBase-formControl"] {
    height: 70px !important;
    background-color: #FFFFFF !important;
  }
  
  .select-drop-down-label-styling>[class *="MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"] {
    height: 70px !important;
  }
  
  /* Css class for label top when filled and shrink */
  
  .select-drop-down-label-styling>[class*="MuiInputLabel-shrink"] {
    top: 0px !important
  }
  
  .title-text {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 22px !important;
    color: #003087 !important;
  }
  
  .check-box-text,
  .radio-button-text [class *="MuiTypography-root"],
  .radio-button-heading {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    width: 85% !important;
    max-width: 100% !important;
  }
  
  .radio-button-heading {
    font-weight: 600 !important;
    font-size: 17px !important;
    color: #003087 !important;
  }
  
  .check-box-text,
  .radio-button-text [class *="MuiTypography-root"] {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #000000 !important;
    white-space: nowrap;
  }
  
  .signature-text {
    font-family: 'Myriad Pro' sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #000000 !important;
  }
  
  .text-area-style {
    margin-top: 15px !important;
    width: 80% !important;
    max-width: 100% !important;
    display: block;
  }
  
  .label-style-create-franchisee,
  .input-field-dropdown-styling ,.label-select, .submit-button-text{
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
   
    
  }
  .label-style-create-franchisee,
  .input-field-dropdown-styling ,.label-select
  {
    color: #053288 !important;
  }

  .submit-button-text
  {
    color:#FFFFFF !important;
  }

  .label-style-create-franchisee,
  .input-field-dropdown-styling
  {
    letter-spacing: -0.05em !important;
    top: 8px !important;
  }
  
  .label-style-create-franchisee.Mui-focused {
    top: 0px !important;
  }
  
  .label-style-create-franchisee.MuiInputLabel-shrink {
    top: 0px !important;
  }
  
  .select-drop-down-label-styling>[class *="Mui-focused"] {
    top: 0px !important;
  }
  
  .MuiSvgIcon-root-MuiSelect-icon {
    color: #003087 !important;
  
  }
  
  .radio-button-style [class *="MuiSvgIcon-root"] {
    color: #003087 !important;
  
  }

  /* Css class for add task button height */
  .button-style-add-task
  {
    height: 56px;
  }

  /* Css class for Dialog text styling */

.dialog-text [class *="MuiTypography-root"]
{
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  color: #000000 !important;
  font-size: 20px;
  font-weight: 700;
  margin-top: 4px;
  letter-spacing: 2.7px;
  /* padding-left: 22px !important; */
}

/* .dialog-box-right-addTask [class *="MuiTypography-root-MuiDialogTitle-root"]
{
  padding: 0px !important;
} */

/* Css class for grid container top margin */

.grid-container[class *="MuiGrid-root"]
{
    margin-top: -8px !important;
}

.select-staff [class *="MuiInputBase-formControl"] {
    height: 70px !important;
    
  }

.select-staff [class *="MuiSvgIcon-root"] 
{
    color: #003087 !important;
}
/* 
.select-staff
{
  margin-top: 36px !important;
} */

/* Css class for input field border color change on focus */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {

    border: 2px solid #003088 !important;
    border-radius: 12px;
  }
  
  /* Css class for input field border  color when not focused */
  
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  
    border: 2px solid rgba(0, 48, 135, 0.5) !important;
    border-radius: 12px;
  }
  
  /* Css class for adding the styles to cross button */
  .cross-button
  {
    border: 2px solid #E64646 !important;
    border-radius: 50% !important;
  }
 
  .details-remove-icon.task-cross {
    margin-right: -16px;
  }