
.detail-table.campaign-table .MuiGrid-container .custom-table-label .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-color: #fff;
    border: 1px solid #d3d3d378;
}

/* .detail-table.campaign-table th {
    background-color: #fff !important;
} */

/* .detail-table.campaign-table .Search-field {
    width: 65%;
} */

/* .campaign-table .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    height: 45px;
}

.campaign-table .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary {
    top: -4px;
    font-size: 16px;
    font-weight: 900;
} */

/* .campaign-table .Search-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 2px solid #7c95c3ed !important;
} */

.campaign-form .MuiOutlinedInput-notchedOutline {
    border: 2px solid #7c95c3ed !important;
}

.campaign-form {
    padding: 0 40px;
}

.campaign-form .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary {

    font-size: 16px;
    font-weight: 900;
}

.campaign-form .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
    color: #003088;
}

.campaign-add-btn {
    height: 50px;
    width: 210px;
    font-size: 18px;
}

.campaign-form-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 40px;
}

.campaign-form-section .form-item {
    width: 100%;
    display: flex;
    gap: 30px;
}

.campaign-form-section .form-radio {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.schedule-campaign .form-radio{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.form-radio .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 60%;
}

.form-radio.schedule-campaign-radios .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}
.schedule-campaign{
    display: flex;
    flex-direction: column;
    gap: 40px;

}
.schedule-campaign .form-radio.schedule-campaign-radios .MuiFormGroup-root {
    display: flex;
    gap: 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
.schedule-campaign .custom-radio-group .MuiFormGroup-root .MuiFormControlLabel-root{
    width: 0;

}

.form-radio .custom-radio-group .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked:nth-child(2) .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    background: #7c95c3bd;
}

.form-radio .custom-radio-group .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary {
    color: #7c95c3bd;
}

.form-radio .custom-radio-group .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
    color: transparent;
}

.form-radio .custom-radio-group .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked span:nth-child(2) svg:nth-child(2) {
    color: #003087;
}

.form-radio .custom-radio-group .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked span:nth-child(2) svg:nth-child(1) {
    background: #7c95c387;
    border-radius: 50%;
}

.blue-heading {
    font-size: 20px !important;
    color: #003087 !important;
    font-weight: 900 !important;
}

.gray-heading {
    font-size: 16px;
    color: gray;
    font-weight: 900;
}

.template-link {
    position: absolute;
    right: 94px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.template-link:hover {
    color: #7c95c3 !important;

}

.campaign-footer {
    padding: 0 40px;
}

.custom-radio-group .radio-item-grouped {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    width: 50%;
}

.custom-radio-group .radio-item-grouped .MuiFormGroup-root .MuiFormControlLabel-root {
    min-width: 80px;

}

.schedule-date-section {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 50px;
}

.schedule-date-section .MuiBox-root {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.schedule-date-section .MuiBox-root .MuiFormControl-root.MuiTextField-root {
    width:50%
}

.schedule-date-section .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: #003087;
}

.review-main {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.review-main .field-section-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
}
.field-section-main .field-item{
    width: 49%;
    display: flex;
    gap: 100px;
}
.field-item .MuiStack-root:nth-child(1){
    font-size: 16px;
    font-weight: bolder;
    color: #3b3b3b;
    width: 40%;
}
.field-item .MuiStack-root:nth-child(2){
    font-weight: 900;
    width: 60%;
    font-size: 16px;
}
.review-main .blue-heading{
    font-size: 20px;
}
.schedule-campaign .blue-heading{
    font-size: 20px;
}
.review-main .template-main{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.template-main .heading{
    font-size: 16px;
    font-weight: bolder;
    color: #3b3b3b;
}
.template-main .description{
    font-size: 14px;
    font-weight: bolder;
    color: #5c5c5cc9;
}
.gift-card-buttons .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary{
    border: 2px solid #003087;
}
.templateModal .MuiPaper-root{ min-width: 800px}

.top-button-style.css-10tryqy-MuiButtonBase-root-MuiButton-root{
    background-color: #003087 !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}
.cohort-input-styling.css-1m2g103-MuiFormLabel-root-MuiInputLabel-root{
    margin-top: 15px !important;
}
