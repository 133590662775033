/* CHATS CSS */

.chats > .e-list-container > .e-list-parent.e-ul {
  max-height: 52vh;
  overflow-y: auto;
}
.users {
  height: 90%;
  overflow: hidden;
}

.users > .e-list-container {
  height: 57vh;
  overflow-y: auto;
}
.e-listview .e-list-item.e-hover,
.e-listview .e-list-item.e-active {
  background-color: #fff;
  color: unset;
}

.list-view-container {
  height: 100%;
}

.e-listview:not(.e-list-template) .e-list-item {
  height: 100%;
  margin: 0 0.5rem; /* 8px / 16 */
  padding: 0;
}

.list-view-header {
  height: 4.9074vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  background-color: #003087;
  color: #ffffff;
  font-size: 1.35rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.62rem;
  letter-spacing: -0.027em;

  padding: 19px;
}

.user-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  padding: 8px;

  align-items: center;
  margin-bottom: 0.625rem;

  border-radius: 50px;
  background: #ebeaea;
}

.input-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem; /* 10px / 16 */
  margin-top: 0.625rem;
}

.searchInput {
  width: 100%;
}

.full-name {
  color: #000;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Myriad Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 25.947px; /* 162.168% */
  letter-spacing: -0.32px;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-left: 1rem;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  border-radius: 1.375rem; /* 22px / 16 */
  border: 1px solid #d3d3d34a;
}

.custom-field.chat_searchbox
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ebeaea !important;
  border-radius: 43px !important;
  color: '#000' !important;
}

.custom-field.chat_searchbox
  .MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  height: 44px;
  padding: 0 6px 0 0 !important;
  border-radius: 43px;
}

.chats {
  flex-grow: 1;
  overflow: hidden;
}

.e-input {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.42) !important;
}

.chat-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 547px; /* Adjust the height as needed */
}
.chat-header-name {
  color: #fff !important;
  text-align: center !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-family: Myriad Pro !important;
  font-size: 21.622px !important;
  font-style: normal !important;
  font-weight: 600;
  line-height: 25.947px !important; /* 120% */
  letter-spacing: -0.432px !important;
}
.no-chat-available-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #000;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Myriad Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 25.947px; /* 162.168% */
  letter-spacing: -0.32px;
}

.icon-style {
  width: 21px;
  height: 21px;
  cursor: pointer;
}
.cancel-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 12px;
}
