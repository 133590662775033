.MuiTableContainer-root.Custom-Table.table-container.css-rorn0c-MuiTableContainer-root.MuiTableBody-root.css-apqrd9-MuiTableBody-root.MuiTableRow-root.table-row.css-1inh334-MuiTableRow-root {
  border-color: 1px solid rgba(81, 81, 81, 1) !important;
}
.radioLabel {
  color: black;
  font-size: large;
  font-weight: 700;
}

.css-1ez0rq7-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  color: #7a91bd !important;
}
.css-13rhbfq-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: #7a91bd !important;
}

.css-1ez0rq7-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: #7a91bd !important;
}
.location-details-value{
  
  font-weight: 700;
  font-size: 16px !important;
  /* line-height: 17px; */
  color: #A5A5A5;
}
.location-details-label{
  
  
    font-weight: 700;
    color: #525252;

    text-transform: uppercase;
    /* line-height: 17px; */
  
}

.gift-card-table .edit-btn{
  display: none !important;
}