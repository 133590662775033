@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-querybuilder/styles/material.css";

.e-rule-field{
display: flex;
}
.e-rule-filter,
.e-rule-operator,
.e-rule-value{
  width: 32% !important;;
  padding: 0px 0px 12px 8px !important;
  margin-left: 10px !important;

}
.e-query-builder .e-btn-group input:checked + label.e-btn{
background-color: #003087  !important;
background: #003087  !important;
border: none !important;
padding: 12px 25px!important;
}
.e-group-header{
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}
.e-query-builder .e-btn-group :hover{
 background: transparent !important;
 
}
.e-lib.e-btn-group{
border: 2px solid #003087 !important;
border-radius: 5px !important;
}
.e-btngroup-or-lbl,
.e-btngroup-and-lbl {
  padding: 10px 25px!important;
}
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-right):not(.e-float-input)::after{
  background-color: transparent  !important;
}
.e-query-builder{
    border: none !important;
}
.e-addrulegroup.e-small {
    height: 3.2em !important;
    width: 3.2em !important;
    font-weight: 900 !important;
}
.e-dropdown-popup ul{
    min-width: 150px !important;
    margin-left: 30px !important;

}
.e-query-builder ,
.e-group-container{
    background-color: rgba(248, 251, 255, 1) !important;
}

.e-rule-container{
    background-color: rgba(248, 251, 255, 1) !important;
    margin-bottom: 10px !important;
}
.e-rule-container input {
    padding: 15px !important;
} 
.e-rule-container{
  border-radius: 10px !important;
}
.e-rule-value-delete{
  margin-top: 25px !important;
  
}

.e-input-group input {
  font-family: 'Myriad Pro', sans-serif !important;
}
.e-small.e-css.e-btn.e-round .e-btn-icon{
  font-size: 10px !important;
  font-weight: 800 !important;
}
.e-small.e-btn.e-round .e-btn-icon{
  font-size: 11px !important;
  font-weight: 800 !important;
}
input.e-input::selection{
  background: #003087 !important;
}
.disabled-card {
  opacity: 0.9 !important; 
  pointer-events: none !important; 
}
.e-radio-wrapper {
  margin-top: 25px !important;
  margin-left: 20px !important;
}
.e-radio:checked + label::before{
  border-color: #003087 !important;
}
.e-radio:checked + label::after{
background-color: #003087 !important;
color: #003087 !important;
}
/* .e-dropdown-popup li[aria-label="Add Group"] {
  display: none !important;
} */
.e-query-builder {
  margin: 0 auto;
}
.e-query-builder .query-template-control .cndtnbtn.e-control.e-dropdownlist.e-lib.e-input {
  padding-left: 10px;
}
.e-query-builder .query-template-control span.e-custom-group-btn {
  max-width: 100px;
  border-radius: 5px !important;
  border-width: 1px !important; 
}
.e-query-builder .query-template-control .e-custom-group-btn.e-input-focus::before, .e-custom-group-btn.e-input-focus::after {
  background: transparent !important;
  }

.e-query-builder .query-template-control .e-custom-group-btn{
  padding-left: 10px;
  height: 32px;
}
.e-bigger .e-query-builder .e-custom-group-btn {
  padding-left: 10px;
  height: 36px;
}
.e-query-builder .query-template-control .e-header {
  display: inline-block;
  float: right;
}
.e-query-builder .query-template-control .e-qb-hdr-content {
  display: inline-block;
  padding: 5px;
}
.e-query-builder .query-template-control .e-grp-btn {
  background-color: #003087 !important;
  
}
.e-query-builder .query-template-control .e-cond-btn {
  background-color: #003087 !important;
  border-radius: 10px !important;
  margin: 10px !important;
}
.e-query-builder .query-template-control .e-grp-btn:hover {
  background-color: #003087 !important;
}
.e-query-builder .query-template-control .e-cond-btn:hover {
  background-color: #003087 !important;

}
@media only screen and (max-width: 700px) {
  .e-query-builder .e-header {
      float: none !important;
  }
  .e-query-builder .e-qb-hdr-content {
      padding-left: 0px !important;
      padding-right: 10px !important;
      padding-top: 10px !important;
  }
}
.btn-style-cohort{
  border-radius: 10px !important;
  padding: 10px !important;
  font-family: 'Myriad Pro Regular', Arial, sans-serif !important;
  font-weight: 600;
  font-size: 0.775rem;
  letter-spacing: normal !important;
}