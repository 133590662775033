.e-schedule {
  border: none;
}

.schedule-control-section .e-schedule .e-vertical-view .e-header-cells .e-header-date {
  display: block !important;
}

/* .custom-datepicker:not(.show) .MuiSvgIcon-root{display: none;} */
.custom-datepicker .MuiInputAdornment-root .MuiButtonBase-root {
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 0;
}

.custom-datepicker .MuiInputAdornment-root {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  padding-right: 0 !important;
  margin: 0;
}

.scheduleDatePick .custom-datepicker .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.scheduleDatePick {
  position: relative;
}

.downArrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}

.scheduleFilter {
  margin-left: 20px;
}

.scheduleFilter .MuiSelect-select {
  min-width: 127px;
  justify-content: space-between;
  background: #f8fbff;
}

.e-appointment.borderLeftAgains {
  height: auto;
}

.e-appointment-details .MuiStack-root {
  font-size: 12px;
}

.e-appointment-details .MuiTypography-root {
  font-size: 12px;
}

.e-appointment-details .css-7gvv57-MuiStack-root {
  margin-top: 5px;
}

/* .e-schedule .e-vertical-view .e-day-wrapper .e-appointment.borderLeft{height: auto !important;} */
.enrollClassDialog .MuiDialog-paperScrollPaper {
  min-width: auto;
  width: 783px;
  max-width: 90% !important;
}

.statusDropdown button {
  padding: 5px 5px;
  min-width: 30px;
}

.enrollPopup {
  display: block;
  width: max-content;
  color: #000;
  margin: 5px 0;
}

.enrollPopup button {
  display: block;
  color: #000;
}

.bookClassBtn button {
  width: 100%;
  color: #fff !important;
}
.bookClassBtndisabled button{
  width: 100%;
  background-color: rgba(137, 126, 126, 0.556) !important;
  color: #fff !important;
}
/* .bookClassBtn button:hover{    background-color: #00266c !important;} */
.e-popup-content {
  padding: 10px !important;
}

.e-quick-popup-wrapper {
  width: 210px;
  max-width: 210px;
  min-width: 210px;
}

.css-irwyau-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: #002e8580;
}

.css-jd3d5m.VerticalAppointment-content {
  color: #000;
  white-space: unset;
  text-transform: none;
}

.css-f96zf5.MainLayout-container {
  background-color: #ffff;
}

.e-control.e-schedule.e-lib.custom-work-days.e-keyboard.calenderToggle {
  border: none !important;
}

.listToggle {
  margin-right: 10px;
  margin-left: 10px;
  border: 2px solid rgb(0, 48, 135);
  border-radius: 12px;
}

.weekToggles {
  border-radius: 15px;
  border: 2px solid rgb(0, 48, 135);
  margin-left: 10px;
}

.filterMenu {
  border: 2px solid #00308780 !important;
  padding: 6px !important;
  width: 15% !important;
}

.filterMenu:hover {
  background-color: rgb(0, 48, 135) !important;
  color: #ffff !important;
}

.filterMenuButton {
  border: 2px solid rgb(0, 48, 135) !important;
  padding: 12px;
  width: 20%;
  margin-right: 10px;
  padding: 16px 16px !important;
}

.filterMenuButton:hover {
  background-color: rgb(0, 48, 135) !important;
  color: #ffff !important;
}

.custom-schedular.smart-scheduler .smart-scheduler-view-header-horizontal .smart-scheduler-view-groups-content.groupContent {
  color: gray !important;
  text-align: left !important;
}

#repeatButton26e0 {
  left: 20% !important;
}

.e-schedule .e-vertical-view .e-resource-cells {
  padding-bottom: 10 !important;
  padding-top: 10px !important;
  text-align: left !important;
  height: 61px !important;
}

.e-appointment.borderLeft {
  border-left: 8px solid #fbb500 !important;
}

.borderLeft {
  color: #000 !important;
  background-color: none !important;
}

.borderLeftAgains {
  border-left: 8px solid #8239bc !important;
  color: #000 !important;
}

.e-schedule .e-vertical-view .e-selected-cell {
  background-color: #fff;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment{
  background-color: #D13A0033;
  border-left: 8px solid #8239bc
}

/* ===================Schedule page============= */
.detail-page-icons {
  border-radius: 33px !important;
  display: flex !important;
  padding: 10px !important;
  justify-content: flex-end !important;
  align-items: center !important;
  background-color: #e7eff9 !important;
  min-width: unset !important;
}

.detail-table-icon {
  padding: 7px !important;
}

.detail-table-icon svg {
  width: 18px !important;
  height: 18px !important;
  color: #003087 !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 13px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 7px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
}

.days-of-week-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 8px;
}

.days-of-week-container .day-circle {
  width: 35px;
  height: 35px;
  border: 1px solid #003087;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #003087;
  background-color: white;
  margin-right: 15px;
  cursor: pointer;
}

.days-of-week-container .selected {
  background-color: #003087;
  color: white;
}

.servies-details-table .MuiTableCell-body {
  color: #000 !important;
}

.schedule-cell-dimension.e-schedule .e-vertical-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-content-wrap table col {
  width: 200px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-month-view .e-date-header-wrap table col {
  width: 200px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells {
  height: 200px;
}

.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-content-wrap table col,
.schedule-cell-dimension.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 100px;
}

.schedule-cell-dimension.e-schedule .e-timeline-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-work-cells {
  height: 600px;
}

.e-schedule .e-block-appointment {
  border-left: none !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.schedule-header {
  font-size: 25px !important;
  margin-left: -25px !important;
}

.title-text-sub-success {
  font-size: 15px !important;
  height: 50px !important;
  width: 300px !important;
}

.success-class {
  padding-bottom: 0px !important;
}

.text-black.close-class {
  color: #003087 !important;
}

.alert-data-scheduling {
  color: #003087 !important;
}

.alert-text-scheduling .css-4ggt7d-MuiTypography-root {
  font-size: 20px !important;
  text-align: center !important;
}

.horizontal-line-scheduling {
  margin-left: 20px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.alert-padding-dialog {
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.success-box-schedule {
  height: 150px !important;
}

.cancel-btn-scheduling {
  color: rgba(227, 82, 5, 1) !important;
}

.servies-details-table .red-status.MuiTableCell-body {
  color: red !important;
}

.servies-details-table .green-status.MuiTableCell-body {
  color: green !important;
}

.servies-details-table .black-status.MuiTableCell-body {
  color: black !important;
}

.servies-details-table .paylater-red.MuiTableCell-body {
  color: red !important;
}

.menuprops-status {
  left: 71% !important;
  top: -20% !important;
}


/* Satish css */

/* Styles for iPad in portrait mode */
/* Styles for iPad in portrait mode */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .e-popup.e-popup-open {
    display: block;
    width: max-content !important;
    max-height: 130px !important;
    left: 40% !important;
    top: 50% !important;
  }
}
.scheduling-date-picker{
  min-width: 175px !important;
}

.status-field .MuiSvgIcon-root path {
  fill: rgb(0, 48, 135) !important; 
}
.Main-container , .MuiInputBase-root{
  font-family: inherit !important;
}