/*-------------------------------*/

/* @import './Location-management/component/Location.css';*/
@font-face {
    font-family: 'Myriad Pro';
    src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}

@font-face {
    font-family: 'Myriad Pro Bold';
    src: url('/public/MyriadPro-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('/public/BebasNeue.ttf') format('truetype');
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../../../public/MYRIADPRO-REGULAR.OTF') format('truetype');

}

html,
body {
    height: 100%;
    margin: 0%;
    padding: 0%;
    /*font-family:'Myriad Pro Bold',sans-serif !important;*/
}


/* This is the class for logo image and hero message*/
/* .logo-text
{
  padding-top: 20px;
} */
/* This class is for main container of the webpage as follows*/
.parent-container-main {
    position: relative;
    height: 100vh;
    width: 100%;
}

.parent-container-main .logo-text {
    margin-bottom: 35px;
}


.zoom-room-logo {
    display: flex;
    justify-content: center;
    height: 180px;
}

.zoom-room-line {
    display: flex;
    justify-content: center;
    /* padding: 1%; */
}

.horizontal-line {
    height: 10px;
    width: 194px;
    background-color: #003087;
    border-radius: 54px !important;
    /* margin-top: 5px; */

}

/* Css class for label focused style*/
.css-1ald77x.Mui-focused {
    color: #003087 !important;
}

/* Css class for styling of text inside the input box*/
.css-p0rm37 {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 100% !important;
    color: #053288 !important;
}

.form-section {
    width: 100%;
}

.reset-password-form {
    margin-top: 25px;
}


/* This is the class for form content of the form fields*/
.login-page, .main-form-container {
    /* height: 52vh !important; */
    margin: 30px auto;
}

.password-expired {
    padding-top: 25px;
}

/* This is the class for forgot password page content*/
.forgot-password-page {
    /* height: 52vh !important; */
    margin: 30px auto;

}

/* Css class for tick image icon*/

/* .tick-image
{
  margin-top: 30px;
} */
.form-section .MuiBox-root.tick-container {
    /* margin: 40px 0px; */
    margin-bottom: 20px;
}

.hero-message {
    font-family: 'Bebas Neue', sans-serif !important;
    font-size: 50px !important;
    font-weight: 400 !important;
    color: #003087 !important;
    /*left: 216px !important;
  top: 196px !important;*/
    text-align: center !important;
    line-height: 65px !important;
    letter-spacing: -0.02em !important;
}

/* .hero-text
{
  margin-top: 10px;
} */


.title-text {

    color: #e35205;
    font-weight: 700 !important;
    font-family: 'Myriad Pro Bold', sans-serif !important;
    font-style: normal !important;
    font-size: 30px !important;
    margin-top: 0px !important;

}

.backbtn {
    margin-top: 20px;
}

.backbtn .back-arrow {
    display: flex;
}

.title-text-password-changed {

    color: #e35205;
    font-weight: 700 !important;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-size: 30px !important;
    letter-spacing: -0.01em;
    /* margin-top: 40px !important; */

}

.title-text-password-expired {
    color: #e35205;
    font-weight: 700 !important;
    font-family: 'Myriad Pro Bold', sans-serif !important;
    font-style: normal !important;
    font-size: 30px !important;
    margin-top: 10px !important;
    text-align: center;
}

.link-expired {
    margin: 0px;
    line-height: 25px;
}

.title-text-sub {
    font-family: 'Myriad Pro', sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #000000 !important;
    margin-top: 8px !important;
    letter-spacing: 0.5px !important;
}

.title-text-sub-forgot-password, .sub-text  {
    font-family: 'Myriad Pro', sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #000000 !important;
    letter-spacing: 0.5px !important;
}

.title-text-sub-password-expired {
    font-family: 'Myriad Pro', sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #000000 !important;
    margin-top: 15px !important;
    line-height: 39px;
    letter-spacing: 0.8px !important;
}

.password-expire-text {
    display: flex;
    justify-content: center;

}

.text-top {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 60px;
    font-weight: 400;
    color: #003087;
    left: 216px;
    top: 196px;
    text-align: center;
}

.logo {
    margin-top: 15px;
    min-height: 100% !important;

}


.text-sub-top {
    margin-top: -29px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 60px;
    font-weight: 400;
    color: #003087;
    left: 216px;
    top: 196px;
    text-align: center
}

.header-text {
    display: flex;
    justify-content: center;

}

.label-text {
    font-size: 14px !important;
    color: #525252 !important;
    font-family: 'Myriad Pro', sans-serif !important;
    margin-top: 20px !important;

}

.label-text-secondary {
    font-size: 14px !important;
    color: #000000 !important;
    font-family: 'Myriad Pro', sans-serif !important;
    margin-top: 10px !important;
}

.button-style {
    background-color: #003087 !important;
    text-transform: none !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    margin-top: 30px !important;
    height: 56px !important;

}

.button-style:hover {
    background-color: #003087 !important;

}

.button-style-password-changed {
    background-color: #003087 !important;
    text-transform: none !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    margin-top: 60px !important;
    height: 60px;
}

.button-style-password-expired {
    background-color: #003087 !important;
    text-transform: none !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    margin-top: 30px !important;
    padding-right: 70px !important;
    padding-left: 70px !important;
    height: 60px;
}

.button-style-password-reset {
    background-color: #003087 !important;
    text-transform: none !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    height: 56px !important;

}

.button-text {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #FFFFFF;

}

.form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
    width: 100% !important;
    background-color: #F8FBFF !important;
    box-shadow: -1px 0px 6px rgba(0, 48, 135, 0.3) !important;
    border-radius: 35px 35px 0px 0px !important;
    margin-top: 15px !important;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    /* height: calc(100vh - 370px); */
    height: calc(100vh - 300px);
}


/* For mobile : like samsung grand(480 * 800): */
@media screen and (max-width : 480px){
    .form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
        height: calc(100vh - 320px);
    }
  }
  
  
  /* For iphone: */
  @media screen and (max-width : 320px){
    .form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
        height: calc(100vh - 320px);
    }
  } 
    
  /* For ipad pro: */
  @media screen and (max-width : 1366px){
    .form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
        height: calc(100vh - 150px);
    }
  }
  
  /* For ipad: Landscape */
  @media (min-width : 768px) and (max-width : 1024px){
    .form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
        height: calc(100vh - 359px);
    }
  }

  /* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5) {
    .form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
        height: calc(100vh - 250px);
    }
}

/* Portrait */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1.5) {
    .form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
        height: calc(100vh - 250px);
    }
}

/* Landscape */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 1.5) {
    .form-area, .form-area-forgot-password, .form-area-password-reset, .form-area-password-expired, .form-area-reset-password-request, .form-area-password-changed {
        height: calc(100vh - 150px);
    }
}
/* 
.form-area-forgot-password {
    background-color: #F8FBFF !important;
    box-shadow: -1px 0px 6px rgba(0, 48, 135, 0.3) !important;
    border-radius: 35px 35px 0px 0px !important;
    margin-top: 15px !important;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;

}

.form-area-password-reset {
    background-color: #F8FBFF !important;
    box-shadow: -1px 0px 6px rgba(0, 48, 135, 0.3) !important;
    border-radius: 35px 35px 0px 0px !important;
    margin-top: 15px !important;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;

}

.form-area-password-expired {
    background-color: #F8FBFF !important;
    box-shadow: -1px 0px 6px rgba(0, 48, 135, 0.3) !important;
    border-radius: 35px 35px 0px 0px !important;
    margin-top: 15px !important;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.form-area-reset-password-request {
    background-color: #F8FBFF !important;
    box-shadow: -1px 0px 6px rgba(0, 48, 135, 0.3) !important;
    border-radius: 35px 35px 0px 0px !important;
    margin-top: 15px !important;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;

}

.form-area-password-changed {
    background-color: #F8FBFF !important;
    box-shadow: -1px 0px 6px rgba(0, 48, 135, 0.3) !important;
    border-radius: 35px 35px 0px 0px !important;
    margin-top: 15px !important;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;

} */

.link-style {
    font-size: 14px !important;
    color: #003087 !important;
    font-family: 'Myriad pro' !important;
    font-weight: 600 !important;
    text-decoration: none;
    padding-left: 2px;
    padding-bottom: 2px;
}

.forgot-pass.link-style {
    font-size: 14px !important;
    color: #003087 !important;
    font-family: 'Myriad pro' !important;
    font-weight: 400 !important;
    text-decoration: none;
}

/* Css class for check box text remember me*/
.check-box-text {
    font-size: 16px !important;
    color: #000000;
    font-family: 'Myriad Pro' !important;
    font-style: normal;
    font-weight: 400 !important;
    letter-spacing: 0.01em;
}

.check-box-span {
    color: rgba(0, 0, 0, 0.6);
}

.check-box-span svg {
    width: 0.9em;
    height: 0.9em;
}

/* Css class for error text message styling*/

/*.css-1wc848c-MuiFormHelperText-root.Mui-error
{
  font-family: 'Myriad Pro',sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}*/

/* Css class for error text message styling*/
.css-v7esy {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

/* Css class for back arrow colour styling*/

.back-arrow-style.css-i4bv87-MuiSvgIcon-root {
    margin-top: 1px;
    color: #003087 !important;
}

@media screen and (max-width:480px) and (min-width:320px) {
    .Details-fields-wrapper {
        margin-left: 0px;
    }

}

/* Css for location-management form ends */

@media screen and (max-width:1732px) and (min-width:1440px) {
    .link-expired {
        line-height: 10px;
    }
    .form-section {
        position: relative;
    }

    .logo-image {
        max-width: 100%;
        /* height: 150px; */
    }

    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 53px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 55px !important;
        letter-spacing: -0.02em !important;
    }
}

@media screen and (max-width:1440px) and (min-width:1024px) {
    .logo-image {
        max-width: 100%;
        /* height: 149px; */
    }
    .link-expired {
        line-height: 10px;
    }

    /* .logo-text
  {
    padding-top: 6px;
  } */


    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 57px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 63px !important;
        letter-spacing: -0.02em !important;
    }
}

@media screen and (max-width:1024px) and (min-width:769px) {
    .logo-image {
        max-width: 100%;
        /* height: 143px; */
    }

    /* .logo-text
  {
    padding-top: 8px;
  } */
    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 53px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 59px !important;
        letter-spacing: -0.02em !important;
    }
}

@media screen and (max-width:821px) and (min-width:819px) {
    /* .logo-text
  {
    padding-top: 123px;
  } */
}

@media screen and (max-width:668px) and (min-width:590px) {
    .logo-image {
        max-width: 100%;
        /* height: 140px; */
    }

    /* .logo-text
  {
    padding-top: 199px;
  } */
    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 52px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 62px !important;
        letter-spacing: -0.02em !important;
    }

}

@media screen and (max-width:590px) and (min-width:425px) {
    .logo-image {
        max-width: 100%;
        /* height: 135px; */
    }

    /* .logo-text
  {
    padding-top: 179px;
  } */
    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 51px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 62px !important;
        letter-spacing: -0.02em !important;
    }

}

@media screen and (max-width:425px) and (min-width:412px) {
    .logo-image {
        max-width: 100%;
        /* height: 135px; */
    }

    /* .logo-text
  {
    padding-top: 62px;
  } */
    .title-text-sub-password-expired {
        font-family: 'Myriad Pro', sans-serif !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        color: #000000 !important;
        margin-top: 25px !important;
        line-height: 39px;
        word-spacing: 4px;
        letter-spacing: 0.1px !important;
        text-align: -webkit-center;
        margin-top: -14px;
    }

    .password-expire-text {
        display: flex;
        justify-content: center;

    }

    .title-text-password-expired {
        color: #e35205;
        font-weight: 700 !important;
        font-family: 'Myriad Pro Bold', sans-serif !important;
        font-style: normal !important;
        font-size: 20px !important;
        margin-top: 10px !important;
    }

    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 35px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 48px !important;
        letter-spacing: -0.02em !important;
    }

}

@media screen and (max-width:394px) and (min-width:361px) {
    .title-text-sub-password-expired {
        font-family: 'Myriad Pro', sans-serif !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        color: #000000 !important;
        margin-top: 25px !important;
        line-height: 39px;
        letter-spacing: 0.1px !important;
        text-align: -webkit-center;
        margin-top: -14px;
    }

    .password-expire-text {
        display: flex;
        justify-content: center;

    }

    .title-text-password-expired {
        color: #e35205;
        font-weight: 700 !important;
        font-family: 'Myriad Pro Bold', sans-serif !important;
        font-style: normal !important;
        font-size: 20px !important;
        margin-top: 10px !important;
    }

    .button-text {
        font-family: 'Myriad Pro', sans-serif !important;
        font-size: 13px !important;
    }

    .login-page, .main-form-container {
        padding-top: 0%;
    }

    .logo-image {
        max-width: 100%;
        /* height: 116px; */
    }

    /* .logo-text {
    padding-top: 83px;
} */

    /* This css class apply for input field padding in mobile view as follows*/
    .MuiOutlinedInput-input {
        padding: 11.5px 14px !important;
    }

    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 31px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 40px !important;
        letter-spacing: -0.02em !important;
    }

    .button-style {
        background-color: #003087 !important;
        text-transform: none !important;
        border-radius: 10px !important;
        color: #FFFFFF !important;
        font-style: normal !important;
        font-weight: 600 !important;
        margin-top: 34px !important;
        height: 49px;
    }

}

@media screen and (max-width:361px) {
    .title-text-sub-password-expired {
        font-family: 'Myriad Pro', sans-serif !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        color: #000000 !important;
        margin-top: 25px !important;
        line-height: 39px;
        letter-spacing: 0.4px !important;
    }

    /* This css class apply for input field padding in mobile view as follows*/
    .MuiOutlinedInput-input {
        padding: 11.5px 14px !important;
    }

    .login-page, .main-form-container {
        padding-top: 0%;
    }

    .logo-image {
        max-width: 100%;
        /* height: 100px; */
    }

    /* .logo-text {
    padding-top: 58px;
} */
    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 31px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 40px !important;
        letter-spacing: -0.02em !important;
    }

    .button-style {
        background-color: #003087 !important;
        text-transform: none !important;
        border-radius: 10px !important;
        color: #FFFFFF !important;
        font-style: normal !important;
        font-weight: 600 !important;
        margin-top: 34px !important;
        height: 44px;
    }

}

@media screen and (max-width:376px) and (min-width:374px) {
    .logo-image {
        max-width: 100%;
        /* height: 100px; */
    }

    /* .logo-text
  {
    padding-top: 30px;
  } */
    .hero-message {
        font-family: 'Bebas Neue', sans-serif !important;
        font-size: 29px !important;
        font-weight: 400 !important;
        color: #003087 !important;
        text-align: center !important;
        line-height: 39px !important;
        letter-spacing: -0.02em !important;
    }

    .button-style {
        background-color: #003087 !important;
        text-transform: none !important;
        border-radius: 10px !important;
        color: #FFFFFF !important;
        font-style: normal !important;
        font-weight: 600 !important;
        margin-top: 10px !important;
        height: 42px;
    }

    .label-text {
        font-size: 14px !important;
        color: #000000 !important;
        font-family: 'Myriad Pro', sans-serif !important;
        margin-top: 0px !important;
    }

}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  background-color: #FFFFFF !important;
} */
input:-webkit-autofill {
    /*   -webkit-box-shadow: 30px 30px 30px 30px white inset !important;#003087 */
    box-shadow: 30px 30px 30px 30px #F8FBFF inset !important;
    caret-color: #003087 !important;
    -webkit-text-fill-color: #003087 !important;
}
/* input:-webkit-autofill {#F8FBFF
    box-shadow: 30px 30px 30px 30px red inset !important;
} */

/* .form-section .MuiBox-root .field-label-css label {
    top:15%;
  } */
/*
.loader-parent {
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-around;
    background: hsla(0, 0%, 100%, .8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483647;
    opacity: 1
}
*/

.loader-parent .blocks-wrapper.loader-class {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}


/* Css for location-management form starts */

.location-form {
    display: flex;
}

.franchisee-name {

    font-family: 'Myriad Pro', sans-serif !important;
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 40px !important;
    color: #003087;
}

.save-button {
    display: flex;
    justify-content: flex-end;
    /*font-family:'Myriad Pro Bold' sans-serif !important ;*/
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 42px !important;
    padding: 13px 20px !important;
    border-radius: 10px !important;
    background-color: #003087 !important;
    color: #FFFFFF !important;
}

.status-text {
    margin-top: 5px !important;
    /*font-family:'Myriad Pro Bold' sans-serif !important ;*/
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #003087;

}

.Details-fields-wrapper {
    margin-left: 50px;
    margin-top: 25px;

}

.Details-fields {
    display: flex !important;
}

.Details-fields-sub {

    margin-top: 35px;
}


/* Css Class for radio button text styling*/
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: 600 !important;
    /*font-family: 'Myriad Pro' sans-serif !important;*/
    font-size: 16px !important;
    font-style: normal;
    color: #000000 !important;
}

.label-style {
    /*font-family: 'Myriad Pro' sans-serif !important;*/
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #053288 !important;
    text-transform: uppercase !important;
    line-height: 14px !important;


}


/*.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root
    {
      letter-spacing: 0.05em !important;
    } */

/* Css class for input field color change on focus*/
/* //, .Email-field, .Pass-field */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {

    border: 2px solid #003088 !important;
    border-radius: 12px;
}

#email-label, #password-label, #Password-label {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #053288 !important;
}


.field-label-css fieldset.MuiOutlinedInput-notchedOutline 
    {
      border: 2px solid #003087 !important;
      outline: none;
      letter-spacing:inherit;
    }

.data-info {
    font-family: 'Myriad Pro' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #000000 !important;
    text-transform: capitalize;
    /*padding: 30px 10px !important;
     height: 76px !important;*/
}


/* Css class for letter spacing*/

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    /*letter-spacing: 0.001000em !important;*/
    letter-spacing: 0.07em !important;
}

/* Css class for drop down text styling*/
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #053288 !important;
}

/* Css class for height of drop-down select*/
/*.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
    {
      height: 76px;
    }*/

/* Css class for radio button styling background-color of check box*/

/*.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked
    {
      color: transparent !important;
    }*/

/*Css class for radio button checked color*/

/*.css-11zohuh-MuiSvgIcon-root
    {
      color: #003087;
    }*/

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    font-family: 'Myriad Pro' sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #053288 !important;

}

/* Css class for drop down arrow color*/

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: #053288 !important;
}



/* Create franchisee css starts*/

.franchisee-name {
    font-family: 'Myraid';
}

.field-top-margin {
    margin-top: 5px !important;
}

#password-helper-text, #Password-helper-text, #email-helper-text {
    color: #D13A00;
    font-family: 'Myriad Pro' sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.para-top {
    margin-bottom: 0px;
}
.para-bottom {
    margin-top: 0px;
}

/* Css class for login page input field label alignment from top*/
.field-label-css #Password-label, .field-label-css #email-label, .field-label-css #password-label {
    top:10px !important;
}
/* Css class for label text when the input field is filled*/
.field-label-css #Password-label[class *="Mui-focused"], .field-label-css #email-label[class *="Mui-focused"], .field-label-css #password-label[class *="Mui-focused"],
.field-label-css #Password-label[class *="MuiFormLabel-filled"], .field-label-css #email-label[class *="MuiFormLabel-filled"], .field-label-css #password-label[class *="MuiFormLabel-filled"] {
    top:0px !important;
}

