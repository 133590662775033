.main-sales-detail {
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 0px 34px;
  flex-direction: column;
  gap: 40px;
}
.main-sales-detail .detailbox-main {
  background-color: #f8fbff;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #d3d3d378;
}
.detailbox-main
  .detail-table
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  background-color: #fff;
  border: 1px solid #d3d3d378;
}
.detail-table
  .MuiTableHead-root.common-header
  .MuiTableRow-root.MuiTableRow-head {
  background: #fff;
}
.detailbox-main .detail-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 90px;
  gap: 20px;
}
.detail-section .section-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.section-main .MuiBox-root {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
}
.section-main .MuiBox-root div:first-child {
  color: #000000a3;
  font-weight: bold;
  font-size: 18px;
}
.section-main .MuiBox-root div:nth-child(2) {
  font-size: 16px;
  font-weight: 900;
}
.detail-buttons-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.detail-buttons-main .gift-card-buttons button {
  width: 135px;
}
.return-main {
  display: flex;
  width: 100%;
  gap: 40px;
  flex-direction: column;
}
.return-main
  .text-field-styling
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  font-weight: 900;
  font-size: 16px;
  background-color: #fff;
}
.text-field-styling.textArea .MuiInputBase-input.MuiOutlinedInput-input {
  height: 112px;
}

.return-table .first-row .MuiTableCell-root{
  border-bottom: none !important;
}