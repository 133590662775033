@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}

@font-face {
  font-family: 'Myriad Pro Bold';
  src: url('/public/MyriadPro-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/public/BebasNeue.ttf') format('truetype');
}


@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}

  /* Css class for card content styling*/

  .product-grid-view .card-content
{
    padding: auto !important;
    background: #F8FBFF;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
    border-color: rgba(0, 48, 135, 0.5);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    aspect-ratio: 4/3;
    
    /* height:150px; */
}
/* Css class for showing edit icon on hovering product card*/
/* .product-card:hover .edit-icon
{
  cursor: pointer;
  display: block !important;
} */

/* Css class for edit product icon on hover*/

.edit-icon
{
  position: absolute;
  display: block !important;
  /* display:none !important; */
  color:#003087 !important;
  right:15px;
}

/* Css class  for card actions styling*/

.product-grid-view .card-actions
{
padding: 15px, 10px, 15px, 10px !important;
background: #FFFFFF;
border-width: 0px 2px 2px 2px;
border-style: solid;
border-color: rgba(0, 48, 135, 0.5);
border-radius: 0px 0px 10px 10px;
padding: 15px 10px !important;
gap: 6px !important;
height:100px;
}

/* Css class common styling of text for search field,list items etc.*/

.text-bottom .search-field [class *="MuiFormLabel-root-MuiInputLabel-root"] .MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root.Mui-selected .product-heading [class *="MuiTypography-root"] .product-details [class *="MuiTypography-root"]
.Filter-text 
{
  font-family: 'Myriad Pro',sans-serif !important;
  font-style: normal;
}

/* Css class for text showing no of items --> 1-6 of 236 items*/

.text-bottom
{
  font-weight: 600;
  font-size: 13px;
  color: #000000 !important;
  margin-top: 20px;
}



/* Css class for styling of search icon*/
.search-icon
{
  color: #7C95C3 !important;
  
}

.filter-field
{
  display: flex;
  flex-direction: column !important;
  justify-content: space-between !important;
  
}

/* Css styling of filter button arrow*/
.filter-field .MuiSvgIcon-root
{
  color: #003087 !important;
  top:12px;
}

/* Css style for filter box styling*/

.filter-field [class *="MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"]
{
  padding: 13px 10px;
  background: #F8FBFF;
}

.Filter-text 
{
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  
}
/* Css class for styling of the active page styling*/

.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root.Mui-selected
{
    background: #003087 !important;
    color:#FFFFFF ;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
}


/* Css class for styling of the input field border when not focused*/

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(0, 48, 135, 0.5) !important;
  border-radius: 12px;
}
/* Css class for formatAlign icon behind the search bar*/
.formatAlign
{
  color: #000000;
  margin-top:10px;
}

/* Css class for View-module-icon icon behind the search bar*/

.view-module-icon
{
  color:#003087;
  margin-left:10px;
  margin-right:10px;
  margin-top:10px;
}

/* Css class for list padding in card actions*/
.card-actions [class*="MuiList-root"]
{
  padding: 0px !important;
}

/* Css class for list item padding in card actions*/

.card-actions [class*="MuiListItem-root"]
{
  padding-left: 0px !important;
}

/* Css class for product heading styling in card actions*/

.product-heading [class *="MuiTypography-root"]
{
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #003087;
}

/* Css class for list sub-item text styling in card actions*/

.product-details [class *="MuiTypography-root"]
{
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  color: #030303 !important;
}
/* Css for line of product details*/
.product-details .MuiTypography-root
{
  line-height: 0.9 !important;
}

/* Css for pagination*/

.pagination-style
{
  margin-top: -7px !important;
}

.product-card:hover
{
  box-sizing: border-box !important;
}
.product-grid-view .product-card
{
  position: relative;
  
  /* height: 250px; */
}
@media  screen  and (max-width:980px) {

  
  
}