.feature-btn {
    background-color: #003087;
    padding: 17px, 20px, 17px, 20px;
}

/* .filter-search {
    margin-top: 20px;
} */

.search-btn {
    /* position: absolute;
    right: 95px;
    height: 40.7px; */
    width: 296px;
}

.search-btn .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 2px solid rgba(0, 48, 135, 0.7) !important;
    border-radius: 12px;
}

.Card {
    margin-top: 30px;
    /* margin-left: 30px;
    margin-right: 35px; */
}

.filter-btn {
    margin-left: 30px;
}

/* h2,
.table-heading {
    margin: 20px 30px !important;
    color: #003087;
    font-size: 20px !important;
} */

.featured-table-container .feature-table-head th {
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;

    font-style: normal !important;
    text-transform: capitalize !important;
    background-color: #F8FBFF !important;
}

/* .featured-table-container {
    margin-left: 20px;ilter-search filter-btn
} */

.featured-table-container .table-body-data .table-cell-data {
    color: #000000 !important;
    font-weight: 400 !important;
}

/* .feature-main-container .back-arrow-close {
    margin-left: -220px !important;
} */

.header-featured-item {
    font-size: 40px ! important;
    font-weight: 600 ! important;
    color: #003087 ! important;
}

/* .back-arrow-close{
    margin-top: 20px !important;
    margin-right: 15px;
    cursor:pointer;
  } */

.feature-main-container .featured-item-save-btn {
    margin-left: 100px !important;
}

.add-featured-container {
    margin-left: 20px;
}

/* .featured-grid-item .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 2px solid rgba(0, 48, 135, 0.7) !important;
    border-radius: 12px;
} */

/* .featured-item-main-container .form-field-label {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #053288 !important;
    letter-spacing: -0.05em !important;
    top: 0px !important;
} */

/* .featured-item-main-container .form-field-label1 {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #053288 !important;
    letter-spacing: -0.05em !important;
    top: 0px !important;
    margin-right: 30px !important;
    background-color: white !important;
} */

/* .input-field-dropdown-styling {
    color: #053288!important;
    font-family: Myriad Pro,sans-serif!important;
    font-size: 16px!important;
    font-style: normal!important;
    font-weight: 600!important;
    letter-spacing: -.05em!important;
    top: 8px;
} */

/* .featured-items .add-btn-featured-item{
    padding: 17px, 20px, 17px, 20px;
} */
/* .feature-add-adjust .css-1ez0rq7-MuiInputBase-root-MuiOutlinedInput-root {
    height: 70px;
}

.feature-add-adjust .css-o416uh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 40px;
} */

.demo-simple-select-helper {
    background-color: white !important;
}

.ellipes {
    width: 90px;
    height: 90px;
    border-radius: 500%;
    background-color: #D9D9D9;
    margin-top: 40px;
}
.image-selected{
    width: 165px;
    height: 165px;
    border-radius: 10%;
    background-color: #F8FBFF;
}
/* .image-tag {
    text-align: center;
   position: relative;
   top: 30px;
   width: 100%;
    color: #003087;
} */

.featured-btn {
    height: 50px;
    width: 93px;
    padding: 17px 20px 17px 20px;
    font-size: 16px;
    line-height: 16px;

}

.featured-choose-btn {
    margin-left: 150px;
    margin-top: -60px;
}

.featured-p-tag {
    font-weight: 600;
    color: #000000;
    margin-left: 150px;
    margin-top: -90px;
}

.bundle-container {
    margin-top: 100px;
}

.checkboxes {
    color: #003087;
}

.filter-btn-featured {
    position: relative;
    top: 30px;
}

.user-details-container .details-row .details-col .details-value {
    font-weight: 700;
    font-size: 16px !important;
    line-height: 40px;
    /* line-height: 17px; */
    letter-spacing: 0.0001rem !important;
    color: #A5A5A5;
}

.user-details-container .details-row .details-col .details-label {
    float: left;
    font-weight: 700;
    line-height: 40px;
    /* letter-spacing:0cap;  */
    color: #525252;
    margin-right: 20px;
    text-transform: uppercase;
    /* line-height: 17px; */
}

.user-details-container .details-row .details-col .featured-img {
    width: 20%;
    margin-left: 50px;
}

.featured-item-main-container,
.css-4r6gm4-MuiContainer-root {
    max-width: 100%;
}

/* .back-arrow-close,
.featured-add-header {
    font-family: 'Myriad Pro', sans-serif ! important;
    font-size: 32px ! important;
    font-weight: 600 ! important;
    color: #003087 ! important;
    position: relative;
} */

/* .featured-item-back-arrow.back-arrow-close {
    margin-top: -2px;
    margin-right: 10px;
    cursor: pointer;
} */

/* .featured-item-main-container,
.css-j14eox-MuiGrid-root {
    margin-top: 0px !important;
} */

.btn-bundle {
    margin-top: 30px;
    margin-left: 25px;
    padding: 12px;
}
/* 
 .image-with-txt {
    margin-left: 130px;
    position: relative;
    bottom: 100px;
 } */
 .image-avatar{
    width: 165px;
    height: 165px;
    border: 1px solid;
    border-radius: 10% !important;
}
/* .image-selected{
    margin-right: 30px;
}  */
.select-image .css-4ggt7d-MuiTypography-root.add-picture-text{
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
}