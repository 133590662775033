.e-schedule {
  border: none;
}
/* .custom-datepicker .MuiSvgIcon-root{display: none;} */
.custom-datepicker .MuiInputAdornment-root .MuiButtonBase-root {
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 0;
}
.custom-datepicker .MuiInputAdornment-root {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  padding-right: 0 !important;
  margin: 0;
}
/* .custom-datepicker .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none !important;
} */
.scheduleDatePick {
  position: relative;
}
.downArrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
.scheduleFilter {
  margin-left: 20px;
}
.scheduleFilter .MuiSelect-select {
  min-width: 127px;
  justify-content: space-between;
  background: #f8fbff;
}
.e-appointment.borderLeftAgain {
  height: auto !important;
}
.e-appointment-details .MuiStack-root {
  font-size: 12px;
}
.e-appointment-details .MuiTypography-root {
  font-size: 12px;
}
.e-appointment-details .css-7gvv57-MuiStack-root {
  margin-top: 5px;
}
/* .e-schedule .e-vertical-view .e-day-wrapper .e-appointment.borderLeft{height: auto !important;} */
.enrollClassDialog .MuiDialog-paperScrollPaper {
  min-width: auto;
  width: 783px;
  max-width: 90% !important;
}
.statusDropdown button {
  padding: 5px 5px;
  min-width: 30px;
}
.enrollPopup {
  display: block;
  width: max-content;
  color: #000;
  margin: 5px 0;
}
.enrollPopup button {
  display: block;
  color: #000;
}
.bookClassBtn button {
  width: 100%;
  background: rgb(0, 48, 135) !important;
  color: #fff !important;
}
.bookClassBtn button:hover {
  background-color: #00266c !important;
}
.e-popup-content {
  padding: 10px !important;
}
.e-quick-popup-wrapper {
  width: 210px;
  max-width: 210px;
  min-width: 210px;
}

.css-irwyau-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: #002e8580;
}
.css-jd3d5m.VerticalAppointment-content {
  color: #000;
  white-space: unset;
  text-transform: none;
}
.css-f96zf5.MainLayout-container {
  background-color: #ffff;
}
.e-control.e-schedule.e-lib.custom-work-days.e-keyboard.calenderToggle {
  border: none !important;
}
.listToggle {
  margin-right: 10px;
  margin-left: 10px;
  border: 2px solid rgb(0, 48, 135);
  border-radius: 12px;
}
.weekToggle.css-1459mv3-MuiToggleButtonGroup-root {
  border-radius: 13px;
  border: 2px solid rgb(0, 48, 135);
  width: 144px;
  margin-left: 10px;
}

.weekToggle.css-1459mv3-MuiToggleButtonGroup-root
  .MuiToggleButtonGroup-grouped {
  width: 51%;
}
.filterMenu {
  border: 2px solid #00308780;
  padding: 6px;
  width: 15%;
}
.filterMenu:hover {
  background-color: rgb(0, 48, 135);
  color: #ffff;
}
.filterMenuButton {
  border: 2px solid rgb(0, 48, 135);
  padding: 12px;
  width: 20%;
  margin-right: 10px;
}
.filterMenuButton:hover {
  background-color: rgb(0, 48, 135);
  color: #ffff;
}
.custom-schedular.smart-scheduler
  .smart-scheduler-view-header-horizontal
  .smart-scheduler-view-groups-content.groupContent {
  color: gray !important;
  text-align: left !important;
}
#repeatButton26e0 {
  left: 20% !important;
}

.e-schedule .e-vertical-view .e-resource-cells {
  padding-bottom: 10 !important;
  padding-top: 10px !important;
  text-align: left !important;
  height: 61px !important;
}

/* .borderLeft {
    border-left: 8px solid #fbb500 !important;
    border-radius: 10px !important;
    color: #000 !important;
}
.borderLeftAgain {
    border-left: 8px solid #8239bc !important;
    border-radius: 10px !important;
    color: #000 !important;
} */

.e-schedule .e-vertical-view .e-selected-cell {
  background-color: #fff;
}

/* ===================Schedule page============= */
.detail-page-icons {
  border-radius: 33px !important;
  display: flex !important;
  padding: 10px !important;
  justify-content: flex-end !important;
  align-items: center !important;
  background-color: #e7eff9 !important;
  min-width: unset !important;
}

.detail-table-icon {
  padding: 7px !important;
}

.detail-table-icon svg {
  width: 18px !important;
  height: 18px !important;
  color: #003087 !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 13px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 7px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
}

.days-of-week-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 8px;
}

.days-of-week-container .day-circle {
  width: 35px;
  height: 35px;
  border: 1px solid #003087;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #003087;
  background-color: white;
  margin-right: 15px;
  cursor: pointer;
}

.days-of-week-container .selected {
  background-color: #003087;
  color: white;
}

.servies-details-table .MuiTableCell-body {
  color: #000 !important;
}

.schedule-cell-dimension.e-schedule
  .e-vertical-view
  .e-date-header-wrap
  table
  col,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-content-wrap table col {
  width: 200px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells,
.schedule-cell-dimension.e-schedule
  .e-month-view
  .e-date-header-wrap
  table
  col {
  width: 200px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-work-cells {
  height: 200px;
}

.schedule-cell-dimension.e-schedule
  .e-timeline-month-view
  .e-content-wrap
  table
  col,
.schedule-cell-dimension.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 100px;
}

.schedule-cell-dimension.e-schedule .e-timeline-view .e-work-cells,
.schedule-cell-dimension.e-schedule .e-timeline-month-view .e-work-cells {
  height: 600px;
}
