@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}

@font-face {
  font-family: 'Myriad Pro Bold';
  src: url('/public/MyriadPro-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/public/BebasNeue.ttf') format('truetype');
}


@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}

/* Css class for create franchisee and create user form field container*/
.Main-container {
  display: unset !important;
  /* justify-content: center; */
}

/* Css for location-management form starts */

.location-form {
  display: flex;
}

/* Css class for text styling of heading of page as follows*/
.franchisee-name {

  font-family: 'Myriad Pro', sans-serif !important;
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 100% !important;
  color: #003087;
}



.label-style-create-franchisee {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #053288 !important;
  letter-spacing: -0.02em !important;
  top: 8px !important;
}

.edit-address-label {
  top: 6px;
}

/* Css class for Dialog text styling*/

.dialog-text [class *="MuiTypography-root"] {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  /* color: #000000 !important; */
  font-size: 20px;
  font-weight: 700;
  top: 5px !important;
}

/* Css class for button height*/

.button-style-add-team {
  height: 50px !important;
}

/* Css class for label text when the input field is filled*/
.label-style-create-franchisee[class *="Mui-focused"],
.label-style-create-franchisee[class *="Mui-focused"],
.label-style-create-franchisee[class *="Mui-focused"],
.label-style-create-franchisee[class *="MuiFormLabel-filled"],
.label-style-create-franchisee[class *="MuiFormLabel-filled"],
.label-style-create-franchisee[class *="MuiFormLabel-filled"],
.input-field-dropdown-styling[class *="Mui-focused"],
.input-field-dropdown-styling[class *="MuiFormLabel-filled"],
.label-style-create-franchisee[class *="MuiFormLabel-filled"],
.label-style-create-franchisee[class *="Mui-focused"],
.edit-address-label[class *="Mui-focused"],
.edit-address-label[class *="MuiFormLabel-filled"] {
  top: 0px !important;
}

/* Css class for styling of text entered in input fields */

.text-style-create-franchisee {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: rgba(5, 50, 136, 0.5) !important;
}


/* Css class for input field border color change on focus*/
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {

  border: 2px solid #003088 !important;
  border-radius: 12px;
}

/* Css class for input field border  color when not focused*/

/* .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {

  border: 2px solid rgba(0, 48, 135, 0.5) !important;
  border-radius: 12px;
} */


/* Css class for giving height of the input field*/

.location-field [class *="MuiInputBase-root-MuiOutlinedInput-root"] {
  height: 70px !important;
}

/* Css class for height of select drop-down*/

.location-field [class *="MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"] {
  height: 70px !important;
}

.Main-container .location-field .MuiInputBase-formControl,
.Main-container .location-field .MuiInputBase-formControl {
  height: 70px;
  background: #FFFFFF !important;
}


/* Css class for drop-down color*/

.location-field [class*="MuiSvgIcon-root-MuiSelect-icon"] {
  color: #003088 !important;
}

/* Css class for text entered input field styling*/

.location-field [class*="MuiInputBase-input-MuiOutlinedInput-input"],
[class*="MuiInputBase-input-MuiOutlinedInput-input"] {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

/* Css class for input field helper text*/

.location-field [class*="MuiFormHelperText-root"] {
  color: #D13A00 !important;
}

/* Css class for text-area helper text*/



/* Css class for helper text / error message*/
.location-field [class*="MuiFormHelperText-root"],
.location-description [class *="MuiFormHelperText-root"] {

  font-weight: 400 !important;
  font-size: 14px !important;
}


/* Css class for select drop down label text styling*/

.input-field-dropdown-styling {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #053288 !important;
  letter-spacing: -0.02em !important;
  top: 8px;
}

.space_1_7 {
  letter-spacing: 1.7px !important;
}

.franchisee-name {
  font-family: 'Myraid';
}

.field-top-margin {
  margin-top: 5px !important;
}

.field-top-margin-edit-location {
  margin-top: 18px !important;
}

/* Css class for edit location text-field*/

.field-top-margin-edit-location-sub {
  margin-top: 35px !important;
}

.text-area-edit-location {
  background: #FFFFFF !important;
  border: 2px solid rgba(0, 48, 135, 0.5) !important;
  border-radius: 10px !important;
}


/* Css class for next button styling and save button*/

.next-button .MuiTypography-root {
  background-color: #003087 !important;
  padding: 13px 20px !important;
  text-align: center;
  border-radius: 10px !important;

}

/* Css class for back button styling*/

.back-button .MuiTypography-root {
  background: #F8FBFF !important;
  padding: 11px 20px !important;
  border-radius: 10px !important;
  border: 2px solid #003087 !important;
  line-height: 100% !important;
}





/* Css class for next button text styling and for save button*/

.next-button-text {
  font-family: 'Myriad Pro', sans-serif !important;
  color: #FFFFFF !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 100% !important;

}

.back-button-text {
  font-family: 'Myriad Pro', sans-serif !important;
  color: #003087 !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;

}

/* Css Class for drop down portion height*/
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 2.800em !important;
}







/* Css class for add profile text*/

.add-profile-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000000;
  display: flex !important;
  align-items: center !important;
}

/* Css class for image upload button*/

.upload-image {

  margin-top: 25px !important;
  background: #003087 !important;
  border-radius: 10px !important;
  text-transform: none !important;
}

/* Css class for upload button text styling*/

.upload-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #FFFFFF !important;
  letter-spacing: 0.01em !important;

}

/* Media query for create-franchisee fields*/

/* Media query for mobile device*/

@media screen and (min-width:320px) and (max-width:480px) {
  .container-text-field {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width:1280px) {

  .add-profile-text {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #000000 !important;
    display: flex !important;
    align-items: center !important;

  }

}