@font-face {
    font-family: 'Myriad Pro';
    src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}

@font-face {
    font-family: 'Myriad Pro Bold';
    src: url('/public/MyriadPro-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('/public/BebasNeue.ttf') format('truetype');
}


@font-face {
    font-family: 'Myriad Pro';
    src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}


.page-title,
.table-title-text {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    color: #003087
}

.page-title {
    font-size: 40px !important;
}

.top-button-style {
    border: 2px solid #003088 !important;
}

.add-item-button {
    height: 62px !important;
}

.table-title-text {
    font-size: 20px !important;
}

.filter-search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.table-container-style {
    /* margin-top: 20px; */
    position: relative;
}

/* .search-box-styling .css-109o592-MuiInputBase-root-MuiOutlinedInput-root
{
    height: 40.7px !important;
} */

.search-box-styling {
    width: 15% !important;
}

.search-box-styling>[class *="MuiInputBase-root-MuiOutlinedInput-root"] {
    height: 40.7px !important;
}

.search-box-styling>[class *="MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline"] {
    border: 2px solid#003088 !important;
    border-radius: 12px;
}



.search-box-styling>[class *="MuiFormLabel-root-MuiInputLabel-root"] {
    top: -3px !important;
}

.Mui-focused.label-style-text-field-search-box {
    top: 0px !important;
}


.search-box-styling .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.text-field-styling .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 2px solid rgba(0, 48, 135, 0.7) !important;
    border-radius: 12px;
}

.Mui-disabled.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 2px solid rgba(0, 48, 135, 0.7) !important;
    border-radius: 12px;
}

.search-box-styling .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.text-field-styling .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 2px solid #003088 !important;
    border-radius: 12px;
}


.MuiFormControl-root-MuiTextField-root {
    width: 20% !important;
}

.table-container-style,
.table-header th,
.add-pricing-box-text,
.font-weight-600 {
    color: #000000 !important;
    font-size: 16px !important;
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    text-transform: capitalize !important;
}

.table-container-style .table-header th {
    font-weight: 600 !important;
}


.table-container-style .table-row .table-td {
    color: #000000 !important;
    font-weight: 400 !important;
}



/* Add pricing styling*/

.add-pricing-box {
    display: flex;
    justify-content: space-between;
}

.add-pricing-box-text {
    font-weight: 400 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.vertical-middle-text {
    display: flex;
    align-items: center;
}

.font-14 {
    font-size: 14px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.label-style-text-field,
.field-heading-text,
.text-field,
.text-availiable,
.common-text,
.smart-list {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    /* font-size: 16px !important; */
    letter-spacing: -0.05em !important;

}

.label-style-text-field {
    top: 8px !important;
    color: #003087 !important;
}

.field-heading-text {
    color: #003087 !important;
}

.text-field {
    color: #000000 !important;
}

.text-availiable {
    color: #003087 !important;
}

.smart-list {
    color: #525252
}

.text-field-styling>[class *="MuiInputBase-root-MuiOutlinedInput-root"] {
    height: 70px !important;
}

.text-details-sub {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #000000 !important;
}


.Mui-focused.label-style-text-field {
    top: 0px !important;
}



.MuiInputLabel-shrink.label-style-text-field {
    top: 0px !important;
}

.submit-button {
    height: 50px !important;
    background-color: #003087 !important;
    color: white !important;
}


.grid-margin {
    margin-top: 10px !important;
}


/* Css class for add promotion management */
.card-pb-40,
.card-pb-10 {
    border: 0.4px solid #C5C5C5;
    background-color: aliceblue;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 0px !important;

}

/* .card-pb-40 {
    padding-bottom: 40px !important;
} */

.card-pb-10 {
    padding-bottom: 10px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.main-container-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle-error-message {
    color: #D13A00;
}

.group-icon {
    margin-right: 10px;
    border: 1px solid;
    padding: 2px;
}