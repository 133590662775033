@font-face {
  font-family: 'Myriad Pro';
  src: url('/public/MYRIADPRO-REGULAR.OTF') format('truetype');
}

@font-face {
  font-family: 'Myriad Pro Bold';
  src: url('/public/MyriadPro-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/public/BebasNeue.ttf') format('truetype');
}

/* Css class for create franchisee and create user form field container*/
.Main-container
{
  display: flex;
  justify-content: center;
}

.MuiFormHelperText-root.Mui-error {
  font-family: 'Myriad Pro',sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #D13A00;
}

.final-error {
  padding-top: 15px;
}
/* input[type="text"] {
  background: #ffffff !important;
} */

.Main-container .container-text-field #location-form .MuiInputBase-formControl, .Main-container .container-text-field #user-form .MuiInputBase-formControl {
  height: 70px;
  background: #FFFFFF !important;
}
.label-style-create-franchisee {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #053288 !important;
  letter-spacing: -0.05em !important;
  top:8px !important;
}

.input-field-styling > [class *="MuiSvgIcon-root-MuiSelect-icon"] {
	color: #003088;
}

/* Css class for date picker label style*/
.label-style-datePicker-create-franchisee
{
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #053288 !important;
  letter-spacing: -0.05em !important;
}
.create-franchise-form {
  background: #F8FBFF !important;
  border: 0.4px solid #C5C5C5 !important;
} 
.select-drop-down-label-styling > [class *="Mui-focused"]
{
  top:0px !important;
}
.input-field-dropdown-styling {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #053288 !important;
  letter-spacing: -0.05em !important;
  top:8px !important;
}

/* Css class for giving height of the input field*/

.input-field-styling > [class *="MuiInputBase-root-MuiOutlinedInput-root"]
{
  height: 70px !important;
  background-color: #FFFFFF !important;
  
}

.input-field-styling > [class *="Mui-focused"], .input-field-styling .label-style-create-franchisee.Mui-focused
{
  top:0px !important
}
.MuiFormLabel-filled {
  top:0px !important
}

/* Css styling for select drop down height*/
/* Css styling for date picker height */

.date-picker > [class *="MuiInputBase-root-MuiOutlinedInput-root"], .input-field-styling > [class *="MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"]
{
  height: 70px !important;
}

/* Css class for form fields width and margin*/

.container-text-field
{
  width: 100%;
  margin-top: 15px;
}


/* Css for location-management form starts */

.location-form {
  display: flex;
}

/* Css class for text styling of heading of page as follows*/
.franchisee-name {

  font-family: 'Myriad Pro', sans-serif !important;
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 40px !important;
  color: #003087;
}

/* Css Class for radio button text styling*/
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-weight: 600 !important;
  /*font-family: 'Myriad Pro' sans-serif !important;*/
  /*font-size: 16px !important;
  font-style: normal;
  color:#000000 !important;*/
}

/* Css class for styling of text entered in input fields */

.text-style-create-franchisee {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: rgba(5, 50, 136, 0.5) !important;
}


/*.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root
{
  letter-spacing: 0.05em !important;
} */

/* Css class for input field border color change on focus*/
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {

  border: 2px solid #003088 !important;
  border-radius: 12px;
}

/* Css class for input field border  color when not focused*/

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {

  border: 2px solid rgba(0, 48, 135, 0.5) !important;
  border-radius: 12px;
}


/*.MuiInputBase-formControl .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
{
  border: 5px solid #003087 !important;
  outline: none;
  letter-spacing:inherit;
}*/


/* Css class for giving height of the input field*/

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 19px 17px !important;
  height: 2.4375em !important;
}



/* Css class for letter spacing*/

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {

  letter-spacing: 0.07em !important;
}

/* Css class for select drop down label text styling*/

.input-field-dropdown-styling {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #053288 !important;
  letter-spacing: -0.05em !important;
}

/* Css class for height of drop-down select*/
/*.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
{
  height: 76px;
}*/

/* Css class for radio button styling background-color of check box*/

/*.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked
{
  color: transparent !important;
}*/

/*Css class for radio button checked color*/

/*.css-11zohuh-MuiSvgIcon-root
{
  color: #003087;
}*/



/* Css class for drop down arrow color*/

/* .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #053288 !important;
} */
/* Create franchisee css starts*/

/* .franchisee-name {
  font-family: 'Myraid';
} */

.field-top-margin {
  margin-top: 5px !important;
}


/* Css class for next button styling and save button*/

/* .next-button .MuiTypography-root {
  background-color: #003087 !important;
  padding: 13px 20px !important;
  text-align: center;
  border-radius: 10px !important;
  line-height: 100% !important;
} */

/* Css class for back button styling*/

.back-button .MuiTypography-root {
  background: #F8FBFF !important;
  padding: 11px 20px !important;
  border-radius: 10px !important;
  border: 2px solid #003087 !important;
  line-height: 100% !important;
}
/* Css class for next button text styling and for save button*/

.next-button-text {
  font-family: 'Myriad Pro', sans-serif !important;
  color: #FFFFFF !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;

}

.back-button-text {
  font-family: 'Myriad Pro', sans-serif !important;
  color: #003087 !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;

}

/* Css Class for drop down portion height*/
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 2.800em !important;
}

/* Css class for step bar text styling when on active page*/
.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-active, .step-labeling .MuiStepLabel-label.Mui-completed {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  color: #003087 !important;
}

/* Css class for step bar text styling when on remaining page*/

.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label, .step-labeling span.Mui-disabled {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000000 !important;
}

/* Css class for step bar text styling when page is completed*/

.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-completed, .step-labeling, .step-labeling span.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  letter-spacing: -0.022em;
  color: #003087 !important;
}

input:-webkit-autofill {
  /*   -webkit-box-shadow: 30px 30px 30px 30px white inset !important;#003087 */
  box-shadow: 30px 30px 30px 30px #F8FBFF inset !important;
  caret-color: #003087 !important;
  -webkit-text-fill-color: #003087 !important;
}

/* Css for border none when the step one is completed */
.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel.Mui-completed {
  border: none !important;
}


.step-labeling>.MuiStepLabel-iconContainer {

  border-radius: 50% !important;
  border: 2px solid #003087 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;


}

/* Css class for step bar when it is not active */
.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root {
  color: transparent !important
}

.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root.Mui-active {
  color: #003087 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 0.8rem !important;
  width: 0.8rem !important;

}

/* Css class for display text of step no as none*/

.MuiStepIcon-text {
  display: none !important;
}

/* Css for step bar when step is completed*/

.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.Mui-completed {
  color: #003087 !important;
  margin: -3px !important;
  vertical-align: middle !important;
  height: auto !important;
  border-radius: 100% !important;
  width: 1.5em !important;
}

.create-franchise-form {
  border-radius: 19px;
  padding: 20px;
}

/* .create-franchise-form .next-button:hover {
  background-color: unset;
} */




/* Css styling for horizontal line container*/
.MuiStepConnector-root {
  top: 16px !important;
  left: calc(-50% + 17px) !important;
  right: calc(50% + 17px) !important;
}

/* Css styling for horizontal line */

.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #003087 !important;
}



/* Css class for add profile text*/

.add-profile-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000000;
  display: flex !important;
  align-items: center !important;
}

/* Css class for image upload button*/

.upload-image {

  margin-top: 25px !important;
  background: #003087 !important;
  border-radius: 10px !important;
  text-transform: none !important;
}

/* Css class for upload button text styling*/

.upload-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #FFFFFF !important;
  letter-spacing: 0.01em !important;

}

.franchise-table-container .edit-btn {
  margin-top: -10px;
} 

/* Media query for create-franchisee fields*/

/* Media query for mobile device*/

@media screen and (min-width:320px) and (max-width:480px) {
  .container-text-field {
    width: 100% !important;
    max-width: 100% !important;
  }

  /* Css styling for horizontal line container*/
  .MuiStepConnector-root {
    top: 16px !important;
    left: calc(-50% + 8px) !important;
    right: calc(50% + 17px) !important;
  }

}

@media screen and (max-width:1280px) {

  .add-profile-text {
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #000000 !important;
    /* margin-left: 19px !important; */
    display: flex !important;
    align-items: center !important;

  }

}