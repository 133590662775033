


.my-dropdown-arrow .MuiButton-endIcon {
    transition: all 0.2s ease-in-out;
  }

  .my-dropdown-arrow.expanded .MuiButton-endIcon {
    transform: rotate(360deg);
  }
  .dropdown-container {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px; /* adjust this value as needed */
  }

 